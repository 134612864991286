import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Web3 from 'web3';
import { ERC20_ABI, TOKEN_ADDRESSES, TOKEN_DECIMALS, TOKEN_TO_USD_RATES } from './constants/contracts';
import './styles.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const BBB_TO_USD_RATE = 0.09;

const PaymentPopup = ({ onClose, onSuccess, prefillAddress = '' }) => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [walletAddress, setWalletAddress] = useState(prefillAddress);
    const [amountState, setAmount] = useState('0.00');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [bbbAmount, setBbbAmount] = useState('');
    const [walletAddressError, setWalletAddressError] = useState('');
    const [selectedCrypto, setSelectedCrypto] = useState('USDT');
    const [cryptoAmount, setCryptoAmount] = useState('');
    const [isConnectingMetaMask, setIsConnectingMetaMask] = useState(false);

    const BBB_AMOUNT_OPTIONS = [1000, 500, 100];

    useEffect(() => {
        if (prefillAddress) {
            validateWalletAddress(prefillAddress);
        }
    }, [prefillAddress]);

    const handleBbbAmountSelect = (bbbValue) => {
        setBbbAmount(bbbValue);
        const usdAmount = (bbbValue * BBB_TO_USD_RATE).toFixed(2);
        setAmount(usdAmount);
        updateCryptoAmount(usdAmount, selectedCrypto);
    };

    const updateCryptoAmount = (usdAmount, cryptoType) => {
        const rate = TOKEN_TO_USD_RATES[cryptoType];
        const cryptoValue = (parseFloat(usdAmount) / rate).toFixed(6);
        setCryptoAmount(cryptoValue);
    };

    const handleCryptoChange = (e) => {
        const cryptoType = e.target.value;
        setSelectedCrypto(cryptoType);
        updateCryptoAmount(amountState, cryptoType);
    };

    const connectMetaMask = async () => {
        setIsConnectingMetaMask(true);
        setError('');
        try {
            if (!window.ethereum) {
                throw new Error('MetaMask is not installed. Please install MetaMask to continue.');
            }

            const accounts = await window.ethereum.request({ 
                method: 'eth_requestAccounts' 
            });
            
            if (accounts && accounts.length > 0) {
                setWalletAddress(accounts[0]);
                setWalletAddressError('');
            } else {
                throw new Error('No accounts found. Please connect your MetaMask wallet.');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setIsConnectingMetaMask(false);
        }
    };

    const validateWalletAddress = (address) => {
        try {
            if (!address) {
                setWalletAddressError('Wallet address is required');
                return false;
            }
            if (!Web3.utils.isAddress(address)) {
                setWalletAddressError('Invalid wallet address format');
                return false;
            }
            setWalletAddressError('');
            return true;
        } catch (err) {
            setWalletAddressError('Invalid wallet address');
            return false;
        }
    };

    const handleWalletAddressChange = (e) => {
        const address = e.target.value;
        setWalletAddress(address);
        validateWalletAddress(address);
    };

    const handleCryptoPayment = async () => {
        try {
            if (!window.ethereum) {
                throw new Error('Please install MetaMask to make crypto payments');
            }

            const accounts = await window.ethereum.request({ 
                method: 'eth_requestAccounts' 
            });
            
            if (!accounts || accounts.length === 0) {
                throw new Error('No accounts found. Please connect your MetaMask wallet.');
            }

            const userAddress = accounts[0];
            const web3 = new Web3(window.ethereum);

            console.log('Connected wallet:', userAddress);
            console.log('Selected token:', selectedCrypto);
            console.log('Token address:', TOKEN_ADDRESSES[selectedCrypto]);
            console.log('Receiver address:', process.env.REACT_APP_CRYPTO_WALLET_ADDRESS);

            const tokenContract = new web3.eth.Contract(
                ERC20_ABI,
                TOKEN_ADDRESSES[selectedCrypto]
            );

            const decimals = TOKEN_DECIMALS[selectedCrypto];
            const tokenAmount = BigInt(
                Math.round(parseFloat(cryptoAmount) * Math.pow(10, decimals))
            ).toString();

            console.log('Token amount:', tokenAmount);

            try {
                const balance = await tokenContract.methods.balanceOf(userAddress).call();
                console.log('User balance:', balance);
                
                if (BigInt(balance) < BigInt(tokenAmount)) {
                    throw new Error(`Insufficient ${selectedCrypto} balance`);
                }
            } catch (err) {
                console.error('Balance check failed:', err);
                throw new Error(`Failed to check ${selectedCrypto} balance: ${err.message}`);
            }

            console.log('Preparing transfer data...');
            const transferData = tokenContract.methods.transfer(
                process.env.REACT_APP_CRYPTO_WALLET_ADDRESS,
                tokenAmount
            ).encodeABI();

            console.log('Estimating gas...');
            let gasEstimate;
            try {
                gasEstimate = await web3.eth.estimateGas({
                    from: userAddress,
                    to: TOKEN_ADDRESSES[selectedCrypto],
                    data: transferData
                });
                console.log('Estimated gas:', gasEstimate);
            } catch (err) {
                console.error('Gas estimation failed:', err);
                throw new Error(`Gas estimation failed: ${err.message}`);
            }

            console.log('Sending transaction...');
            const tx = {
                from: userAddress,
                to: TOKEN_ADDRESSES[selectedCrypto],
                data: transferData,
                gas: gasEstimate
            };

            console.log('Transaction payload:', tx);
            const txHash = await web3.eth.sendTransaction(tx);
            console.log('Transaction successful:', txHash);
            return txHash;
        } catch (err) {
            console.error('Crypto payment error:', err);
            if (err.message.includes('user rejected')) {
                throw new Error('Transaction was rejected by user');
            }
            throw new Error(`Crypto payment failed: ${err.message}`);
        }
    };

    const handlePayment = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (!validateWalletAddress(walletAddress)) {
            setLoading(false);
            return;
        }

        try {
            if (paymentMethod === 'card') {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/create-checkout-session`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        amount: amountState,
                        bbbAmount: bbbAmount,
                        walletAddress: walletAddress
                    }),
                });

                const session = await response.json();
                if (session.error) {
                    throw new Error(session.error);
                }

                const stripe = await stripePromise;
                if (!stripe) {
                    throw new Error('Failed to load Stripe');
                }

                const { error } = await stripe.redirectToCheckout({
                    sessionId: session.id
                });

                if (error) {
                    throw new Error(error.message);
                }
            } else if (paymentMethod === 'crypto') {
                try {
                    const txHash = await handleCryptoPayment();
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/crypto-payment-complete`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            txHash,
                            amount: parseFloat(amountState),
                            cryptoAmount: parseFloat(cryptoAmount),
                            cryptoType: selectedCrypto,
                            walletAddress,
                            bbbAmount: parseFloat(bbbAmount)
                        }),
                    });

                    if (!response.ok) {
                        throw new Error('Failed to record payment');
                    }

                    onClose();
                } catch (error) {
                    console.error('Payment error:', error);
                    setError(error.message);
                }
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>×</button>
                <h2>Purchase BBB Tokens</h2>
                
                {error && <div className="error-message">{error}</div>}
                
                <form onSubmit={handlePayment}>
                    <div className="form-group">
                        <label>Payment Method:</label>
                        <select 
                            value={paymentMethod} 
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            required
                        >
                            <option value="">Select payment method</option>
                            <option value="card">Credit Card</option>
                            <option value="crypto">Cryptocurrency</option>
                        </select>
                    </div>

                    {paymentMethod === 'crypto' && (
                        <div className="form-group">
                            <label>Select Cryptocurrency:</label>
                            <select 
                                value={selectedCrypto}
                                onChange={handleCryptoChange}
                                required
                            >
                                <option value="USDT">USDT</option>
                                <option value="USDC">USDC</option>
                            </select>
                            <span className="helper-text">
                                Amount: {cryptoAmount} {selectedCrypto}
                            </span>
                        </div>
                    )}

                    <div className="amount-options">
                        <div className="rate-info">
                            1 BBB = ${BBB_TO_USD_RATE.toFixed(2)} USD
                        </div>
                        <label>Select BBB Amount:</label>
                        <div className="bbb-buttons">
                            {BBB_AMOUNT_OPTIONS.map((option) => (
                                <button
                                    key={option}
                                    type="button"
                                    className={`amount-button ${bbbAmount === option ? 'selected' : ''}`}
                                    onClick={() => handleBbbAmountSelect(option)}
                                >
                                    {option} BBB
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Amount (USD):</label>
                        <input
                            type="text"
                            value={amountState}
                            readOnly
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label>Wallet Address:</label>
                        <div className="wallet-input-group">
                            <input
                                type="text"
                                value={walletAddress}
                                onChange={handleWalletAddressChange}
                                required
                                placeholder="Enter your BASE wallet address"
                                className={walletAddressError ? 'error' : ''}
                            />
                            <button 
                                className="metamask-button"
                                onClick={connectMetaMask}
                                disabled={isConnectingMetaMask}
                            >
                                {isConnectingMetaMask ? 'Connecting...' : 'Connect Wallet'}
                            </button>
                        </div>
                        {walletAddressError && <div className="error-message">{walletAddressError}</div>}
                    </div>

                    <button 
                        type="submit" 
                        className="submit-button"
                        disabled={loading || !walletAddress || !amountState || walletAddressError}
                    >
                        {loading ? 'Processing...' : 'Continue'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default PaymentPopup;
