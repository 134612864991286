import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import Confetti from 'react-confetti';
import { ERC20_ABI } from './constants/contracts';
import './styles.css';

const BBB_TOKEN_ADDRESS = '0x6E6f6AE3E52C6877D1e3bd98284ac669219FC25e';
const BBB_DECIMALS = 18;
const FIXED_BBB_AMOUNT = '100';

const ChargePopup = ({ onClose, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [transactionHash, setTransactionHash] = useState('');
    const [showConfetti, setShowConfetti] = useState(false);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePayment = async () => {
        try {
            setLoading(true);
            setError('');
            setShowConfetti(false);

            if (!window.ethereum) {
                throw new Error('Please install MetaMask to make BBB payments');
            }

            // Request account access
            const accounts = await window.ethereum.request({ 
                method: 'eth_requestAccounts' 
            });
            
            if (!accounts || accounts.length === 0) {
                throw new Error('No accounts found. Please connect your MetaMask wallet.');
            }

            const userAddress = accounts[0];
            const web3 = new Web3(window.ethereum);

            // Initialize BBB token contract
            const bbbToken = new web3.eth.Contract(
                ERC20_ABI,
                BBB_TOKEN_ADDRESS
            );

            // Convert amount to token units (with decimals)
            const tokenAmount = web3.utils.toWei(FIXED_BBB_AMOUNT, 'ether');

            // Check user's BBB balance
            const balance = await bbbToken.methods.balanceOf(userAddress).call();
            if (BigInt(balance) < BigInt(tokenAmount)) {
                throw new Error('Insufficient BBB token balance');
            }

            // Get the receiver address from environment variable
            const receiverAddress = process.env.REACT_APP_CRYPTO_WALLET_ADDRESS;
            if (!web3.utils.isAddress(receiverAddress)) {
                throw new Error('Invalid receiver address configuration');
            }

            // Prepare the transaction
            const tx = await bbbToken.methods.transfer(
                receiverAddress,
                tokenAmount
            ).send({
                from: userAddress,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            });

            setTransactionHash(tx.transactionHash);
            setShowConfetti(true);
            
            // Hide confetti after 5 seconds
            setTimeout(() => {
                setShowConfetti(false);
            }, 5000);
            
            if (onSuccess) {
                onSuccess({
                    amount: FIXED_BBB_AMOUNT,
                    transactionHash: tx.transactionHash,
                    fromAddress: userAddress,
                    toAddress: receiverAddress
                });
            }

        } catch (err) {
            console.error('Payment error:', err);
            setError(err.message || 'Failed to process payment');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="popup-overlay">
            {showConfetti && (
                <Confetti
                    width={windowSize.width}
                    height={windowSize.height}
                    recycle={false}
                    numberOfPieces={500}
                    gravity={0.3}
                />
            )}
            <div className="popup-content">
                <div className="popup-header">
                    <h2>Pay with BBB Tokens</h2>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>

                <div className="popup-body">
                    <div className="amount-display">
                        <div className="amount-label">Amount to Pay:</div>
                        <div className="amount-value">{FIXED_BBB_AMOUNT} BBB</div>
                    </div>

                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}

                    {transactionHash && (
                        <div className="success-message">
                            <div className="success-title">Payment successful! 🎉</div>
                            <div className="transaction-hash">
                                Transaction Hash: 
                                <span className="hash-value">{transactionHash}</span>
                            </div>
                        </div>
                    )}

                    <div className="popup-footer">
                        <button
                            className="pay-button"
                            onClick={handlePayment}
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Pay Now'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChargePopup;
