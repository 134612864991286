// ERC20 Token ABI (minimal interface for transfer)
export const ERC20_ABI = [
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }
];

// Token addresses on Base network
export const TOKEN_ADDRESSES = {
    USDT: '0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2', // Base USDT
    USDC: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'  // Base USDC
};

// Token decimals
export const TOKEN_DECIMALS = {
    USDT: 6,
    USDC: 6
};

// Token exchange rates to USD (1:1 for stablecoins)
export const TOKEN_TO_USD_RATES = {
    USDT: 1,
    USDC: 1
};
